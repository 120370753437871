import { v4 } from 'uuid'
import { saveAs  } from 'file-saver'

import { useState } from 'react'
import { useRequest, useThrottleFn } from 'ahooks'

import { Title, Upload, Signature, DataPicker, DatePicker, CascaderPicker } from 'components'
import { Button, Form, Input, Radio, Space, ImageUploader, Modal, TextArea, Toast } from 'antd-mobile'

export default function({ store }) {
  const [ form ] = Form.useForm()
  const [ data, dispatch ] = useState({ showCompanyClassExtraField: false })

  useRequest(() => store.externals.api.get('/h5/certApply/draft').then(res => form.setFieldsValue({
    ...res,
    idClass: res.idClass ? [ res.idClass ] : [ '0' ],
    qualificationTitle: res.qualificationTitle ? [ res.qualificationTitle ] : [],
    gradeClass: res.gradeClass ? [ res.gradeClass ] : [],
    grade: res.grade ? [ res.grade ] : [],
    companyClass: res.companyClass ? [ res.companyClass ] : [],
    portrait: res.portrait ? [{ url: res.portrait }] : [],
    organizationSignRef: res.organizationSignRef ? [{ url: res.organizationSignRef }] : [],
    jobExperience: res.jobExperience ? JSON.parse(res.jobExperience) : [{}],
  })))

  const doSave = useThrottleFn(() => Promise.resolve(form.getFieldsValue()).then(data => store.externals.api.put('/h5/certApply/draft', R.filter(elm => elm != null, Object.assign(data, {
    certApplyCertifyList: data.certApplyCertifyList.map(elm => R.filter(id => id != null, {
      ...elm,
      ref: elm.ref,
      time: elm.time ? moment(elm.time).format('YYYY-MM-DD') : null,
    })),
    portrait: data.portrait?.[0]?.url,
    organizationSignRef: data.organizationSignRef?.[0]?.url,
    companyClass: data.companyClass?.[0],
    qualificationTitle: data.qualificationTitle?.[0],
    idClass: data.idClass?.[0],
    jobExperience: JSON.stringify(data.jobExperience),
    gradeClass: data.gradeClass?.[0],
    grade: data.gradeClass?.join(''),
    signRef: data.signRef ?? '',
    birthday: data.birthday ? moment(data.birthday).format('YYYY-MM-DD') : null,
    rankTime: data.rankTime ? moment(data.rankTime).format('YYYY-MM-DD') : null,
    onjobTime: data.onjobTime ? moment(data.onjobTime).format('YYYY-MM-DD') : null,
    xiantaoTime: data.xiantaoTime ? moment(data.xiantaoTime).format('YYYY-MM-DD') : null,
  })))), {
    wait: 3000,
    leading: true,
    trailing: true,
  })

  const doSubmit = useRequest(data => store.externals.api.put('/h5/certApply', R.filter(elm => elm != null, Object.assign({}, data, {
    certApplyCertifyList: data.certApplyCertifyList.map(elm => R.filter(id => id != null, {
      ...elm,
      ref: elm.ref,
      time: elm.time ? moment(elm.time).format('YYYY-MM-DD') : null,
    })),
    idClass: data.idClass.join(''),
    qualificationTitle: data.qualificationTitle.join(''),
    companyClass: data.companyClass.join(''),
    gradeClass: data.gradeClass[0],
    grade: data.gradeClass.join(''),
    gender: data.sex,
    jobExperience: JSON.stringify(data.jobExperience),
    portrait: data.portrait[0].url,
    organizationSignRef: data.organizationSignRef[0].url,
    birthday: data.birthday ? moment(data.birthday).format('YYYY-MM-DD') : null,
    rankTime: data.rankTime ? moment(data.rankTime).format('YYYY-MM-DD') : null,
    onjobTime: data.onjobTime ? moment(data.onjobTime).format('YYYY-MM-DD') : null,
    xiantaoTime: data.xiantaoTime ? moment(data.xiantaoTime).format('YYYY-MM-DD') : null,
  }))), { manual: true })

  const doDownload = useRequest<any, any>(async () => {
    const response = await fetch(`${store.config.env.api}/h5/export/${store.identity.uid}`, {
      method: 'get',
      mode: 'cors',
      headers: {
        Authorization: store.identity.token,
      }
    })

    const bolb = await response.blob()
    const data = new Blob([ bolb ], { type: 'application/octet-stream' })

    saveAs(
      data,
      decodeURIComponent(response.headers.get('Content-Disposition')?.split(';').pop().split('=').pop())
    )
  }, {
    manual: true,
  })

  return <div className='min-h-screen bg-gray-100 p-1'>
    <Title>
      填写申请信息
    </Title>

    <Form
      form={form}
      layout='vertical'
      mode='card'
      onValuesChange={() => doSave.run()}
      initialValues={{ sex: 'male', jobExperience: R.of({}), certApplyCertifyList: R.of({}) }}
    >
      <Form.Header>基本信息</Form.Header>

      <Form.Item hidden name='id'>
        <Input />
      </Form.Item>

      <Form.Item name='name' label='姓名' rules={[{ required: true }]}>
        <Input placeholder='请输入姓名' />
      </Form.Item>

      <Form.Item name='fname' label='外文名'>
        <Input placeholder='请输入外文名' />
      </Form.Item>

      <Form.Item name='sex' label='性别' rules={[{ required: true }]}>
        <Radio.Group>
          <Space direction='horizontal'>
            <Radio value='male'>男</Radio>
            <Radio value='female'>女</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item name='city' label='户籍' rules={[{ required: true }]}>
        <Input placeholder='请输入户籍' />
      </Form.Item>

      <Form.Item name='nation' label='民族' rules={[{ required: true }]}>
        <Input placeholder='请输入民族' />
      </Form.Item>

      <DatePicker
        name='birthday'
        label='出生日期'
        rules={[{ required: true }]}
      />

      <Form.Item name='portrait' label='电子照片' rules={[{ required: true }]}>
        {React.createElement(({ value, onChange }) => {
          return <React.Fragment>
            <ImageUploader
              value={value}
              onChange={onChange}
              maxCount={1}
              upload={file => store.externals.oss.upload(file).then(res => ({ url: `//${res.url}` }))}
            />
            <div className='mt-1.5 text-sm text-gray-400'>1寸免冠照</div>
          </React.Fragment>
        })}
      </Form.Item>

      <Form.Header>工作信息</Form.Header>

      <Form.Item name='organization' label='工作单位' rules={[{ required: true }]}>
        <Input placeholder='请输入工作单位' />
      </Form.Item>

      <Form.Item name='position' label='职务' rules={[{ required: true }]}>
        <Input placeholder='请输入职务' />
      </Form.Item>

      <Form.Item name='profession' label='从事专业' rules={[{ required: true }]}>
        <Input placeholder='请输入从事专业' />
      </Form.Item>

      <Form.Item name='contact' label='联系方式' rules={[{ required: true }]}>
        <Input placeholder='请输入联系方式' />
      </Form.Item>

      <DataPicker
        columns={[[
          { label: '市级机关及参公单位', value: '1' },
          { label: '市属国有企业', value: '2' },
          { label: '在本市纳税的非公企业', value: '3' },
          { label: '市级事业单位', value: '4' },
          { label: '经市民政局注册的社会组织', value: '5' },
          { label: '其他性质单位', value: '-1' },
        ]]}
        name='companyClass'
        label='单位性质'
        placeholder='请选择单位性质'
        rules={[{ required: true }]}
        onChange={key => dispatch(data => ({ ...data, showCompanyClassExtraField: key == '-1' }))}
      />

      <Form.Item
        name='companyClassExtra'
        label='其他单位性质'
        dependencies={['companyClass']}
        hidden={!data.showCompanyClassExtraField}
        rules={[{ required: data.showCompanyClassExtraField }]}
      >
        <Input placeholder='请输入其他单位性质' />
      </Form.Item>

      <Form.Header>教育经历</Form.Header>

      <Form.Item name='degreeFull' label='全日制教育学历' rules={[{ required: true }]}>
        <Input placeholder='请输入全日制教育学历' />
      </Form.Item>

      <Form.Item name='degreeFullMajor' label='毕业院校及专业' rules={[{ required: true }]}>
        <Input placeholder='请输入全日制教育学历毕业院校及专业' />
      </Form.Item>

      <Form.Item name='degreePart' label='在职教育学历' rules={[{ required: true }]}>
        <Input placeholder='请输入在职教育学历' />
      </Form.Item>

      <Form.Item name='degreePartMajor' label='毕业院校及专业' rules={[{ required: true }]}>
        <Input placeholder='请输入在职教育毕业院校及专业' />
      </Form.Item>

      <Form.Item name='rank' label='职称' rules={[{ required: true }]}>
        <Input placeholder='请输入职称' />
      </Form.Item>

      <DatePicker
        name='rankTime'
        label='职称获取时间'
        rules={[{ required: true }]}
      />

      <DataPicker
        columns={[[
          { label: '专业技术类', value: '0' },
          { label: '技能类', value: '1' },
        ]]}
        name='qualificationTitle'
        label='职业资格'
        placeholder='请选择职业资格'
        rules={[{ required: true }]}
      />

      <Form.Header>证件信息</Form.Header>

      <DataPicker
        columns={[[
          { label: '身份证/社保卡', value: '0' },
          { label: '护照', value: '1' },
        ]]}
        name='idClass'
        label='证件类型'
        rules={[{ required: true }]}
      />

      <Form.Item
        name='idNum'
        label='证件号码'
        rules={[{ required: true }]}
      >
        <Input placeholder='请输入证件号码' />
      </Form.Item>

      <Form.Header>工作经历</Form.Header>

      <DatePicker
        name='onjobTime'
        label='参加工作时间'
        rules={[{ required: true }]}
      />

      <DatePicker
        name='xiantaoTime'
        label='来仙工作时间'
        rules={[{ required: true }]}
      />

      <Form.Array
        name='jobExperience'
        onAdd={data => data.add({ id: v4() })}
        renderAdd={() => <div>添加一项工作经历</div>}
        renderHeader={({ index }, { remove }) => {
          if(index == 0) {
            return <div className='text-gray-500 text-xs'>工作经历{index+1}</div>
          }

          return <div className='flex items-center justify-between'>
            <div className='text-gray-500 text-xs'>工作经历{index+1}</div>
            <div className='text-red-600' onClick={() => Modal.confirm({ content: '确定要删除本项工作经历吗？' }).then(res => res ? remove(index) : null)}>删除</div>
          </div>
        }}
      >
        {fields => fields.map(({ index }) => {
          return <React.Fragment>
            <DatePicker
              name={[index, 'start']}
              label='开始时间'
              rules={[{ required: true }]}
            />

            <DatePicker
              name={[index, 'end']}
              label='结束时间'
              rules={[{ required: true }]}
            />

            <Form.Item
              name={[index, 'desc']}
              label='工作内容概述'
              rules={[{ required: true }]}
            >
              <Input placeholder='请输入工作内容概述' />
            </Form.Item>
          </React.Fragment>
        })}
      </Form.Array>

      <Form.Header>人才评审信息</Form.Header>

      <CascaderPicker
        name='gradeClass'
        label='认定类别'
        options={store.config.dictionary.gradeClass}
        rules={[{ required: true }]}
      />

      <Form.Item
        name='intro'
        label='主要业绩、成果和贡献'
        rules={[{ required: true }]}
      >
        <TextArea
          showCount
          rows={4}
          maxLength={300}
          placeholder='请输入主要业绩、成果和贡献'
        />
      </Form.Item>

      <Form.Header>工作经历</Form.Header>

      <Form.Array
        name='certApplyCertifyList'
        onAdd={data => data.add({})}
        renderAdd={() => <div>添加一项评审依据</div>}
        renderHeader={({ index }, { remove }) => {
          if(index == 0) {
            return <div className='text-gray-500 text-xs'>评审依据{index+1}</div>
          }

          return <div className='flex items-center justify-between'>
            <div className='text-gray-500 text-xs'>评审依据{index+1}</div>
            <div className='text-red-600' onClick={() => Modal.confirm({ content: '确定要删除本项评审依据吗？' }).then(res => res ? remove(index) : null)}>删除</div>
          </div>
        }}
      >
        {fields => fields.map(({ index }) => {
          return <React.Fragment>
            <Form.Item
              name={[index, 'title']}
              label='主要依据（包括奖项、职称、学历等）'
              rules={[{ required: true }]}
            >
              <Input placeholder='请输入主要依据（包括奖项、职称、学历等）' />
            </Form.Item>

            <DatePicker
              name={[index, 'time']}
              label='取得时间'
              rules={[{ required: true }]}
            />

            <Form.Item
              name={[index, 'ref']}
              label='附件'
              rules={[{ required: true }]}
            >
              {React.createElement(({ value, onChange }) => {
                if(value == null) {
                  return <Upload
                  onChange={e => onChange(e)}
                    upload={file => store.externals.oss.upload(file).then(res => `//${res.url}`)}
                  />
                }

                else {
                  return <Button
                    color='danger'
                    size='mini'
                    fill='outline'
                    onClick={() => onChange()}
                  >
                    附件{index + 1}（点击删除）
                  </Button>
                }
              })}
            </Form.Item>
          </React.Fragment>
        })}
      </Form.Array>

      <Form.Header>评审意见</Form.Header>

      <Form.Item name='organizationSignRef' label='所在单位（机构、组织）审核意见' rules={[{ required: true }]}>
        {React.createElement(({ value, onChange }) => {
          return <React.Fragment>
            <ImageUploader
              value={value}
              onChange={onChange}
              maxCount={1}
              upload={file => store.externals.oss.upload(file).then(res => ({ url: `//${res.url}` }))}
            />
            <div className='mt-1.5 text-sm text-gray-400'>审核结果拍照上传</div>
          </React.Fragment>
        })}
      </Form.Item>

      <Form.Header>签名</Form.Header>

      <Form.Item
        name='signRef'
        label='签名（下方白板手写）'
        rules={[{ required: true }]}
      >
        {React.createElement(({ value, onChange }) => {
          return <Signature
            value={value}
            onChange={onChange}
          />
        })}
      </Form.Item>
    </Form>

    <div className='p-4 pb-8 space-y-4'>
      <Button
        block
        color='primary'
        fill='outline'
        loading={doDownload.loading}
        onClick={() => doDownload.runAsync().then(res => console.log(res))}
      >
        下载审核文档
      </Button>

      <Button
        block
        color='primary'
        loading={doSubmit.loading}
        onClick={() => form.validateFields().catch(R.identity).then(() => form.getFieldsError().map(R.prop('errors')).reduce((prev, current) => prev.concat(current), [])).then(errors => {
          if(R.isEmpty(errors)) {
            doSubmit.runAsync(form.getFieldsValue()).then(() => store.navigate.go('/home'))
          }

          else {
            Toast.show(R.head(errors))
          }
        })}
      >
        提交审核
      </Button>
    </div>
  </div>
}
