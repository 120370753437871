import { Form, DatePicker } from 'antd-mobile'

export default function ({ name, label, rules, placeholder = '请选择日期', format = 'YYYY-MM-DD', onChange = R.identity as any }) {
  const [ visible, dispatch ] = React.useState(false)

  return <Form.Item
    arrow
    name={name}
    label={label}
    rules={rules}
    onClick={() => dispatch(true)}
  >
    {React.createElement(({ value, onChange: onSave }) => {
      return <DatePicker
        visible={visible}
        value={R.is(String, value) ? moment(value).toDate() : value}
        onConfirm={R.compose(R.tap(onSave), R.tap(onChange))}
        onClose={() => dispatch(false)}
        min={moment().subtract(80, 'years').toDate()}
      >
        {value => {
          return value ? moment(value).format(format) : <div className='text-gray-300'>{placeholder}</div>
        }}
      </DatePicker>
    })}
  </Form.Item>
}
