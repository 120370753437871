import { Form, Picker } from 'antd-mobile'

export default function ({ name, label, rules, columns, placeholder = `请选择${label}`, onChange = R.identity as any }) {
  const [ visible, dispatch ] = React.useState(false)

  return <Form.Item
    arrow
    name={name}
    label={label}
    rules={rules}
    onClick={() => dispatch(true)}
  >
    {React.createElement(({ value, onChange: onSave }) => {
      return <Picker
        value={value}
        columns={columns}
        visible={visible}
        onClose={() => dispatch(false)}
        onConfirm={R.compose(R.tap(onSave), R.tap(onChange))}
      >
        {value => {
          return R.isEmpty(value) ? <div className='text-gray-300'>{placeholder}</div> : R.head(value).label
        }}
      </Picker>
    })}
  </Form.Item>
}
