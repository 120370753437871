import { useEffect } from 'react'
import { useRequest } from 'ahooks'

import { Title } from 'components'
import { DotLoading, Steps } from 'antd-mobile'

export default function ({ store }) {
  const res = useRequest<any, any>(() => store.externals.api.get('/h5/card/my'), {
    cacheKey: 'card',
    staleTime: 3000,
  })

  if(res.loading) {
    return <div className='h-screen flex items-center justify-center'>
      <DotLoading/>
    </div>
  }

  return <div className='h-screen relative'>
    <div className='p-4'>
      <div className='bg-texture bg-cover bg-no-repeat rounded-t-2xl h-48 pt-4'>
        <div className='flex items-center justify-center space-x-6'>
          <div className='text-white space-y-1'>
            <div>{`${res.data?.name}`}</div>
            <div>证件号：{`${res.data?.card.slice(0, 6)}********${res.data?.card.slice(res.data?.card.length - 4, res.data?.card.length)}`}</div>
            <div>{`${res.data?.level ?? ''}`}</div>
          </div>
          <div>
            <img
              className='w-20 h-24 object-cover'
              src={res.data?.portrait}
            />
          </div>
        </div>
      </div>
    </div>

    <div className='grid grid-cols-3 gap-4 bg-blue-400 p-4 py-8 w-full absolute top-36'>
      <div className='flex flex-col items-center space-y-2' onClick={() => store.navigate.go('/certificate')}>
        <div className='w-10 h-10 rounded-full flex items-center justify-center text-lg text-white bg-gradient-to-b from-watergreen-600 to-watergreen-800'>
          <i className='fa fa-id-card-o'/>
        </div>
        <div className='text-white'>查看认定证书</div>
      </div>

      <div className='flex flex-col items-center space-y-2' onClick={() => store.navigate.go('/receive')}>
        <div className='w-10 h-10 rounded-full flex items-center justify-center text-lg text-white bg-gradient-to-b from-waterpurple-600 to-waterpurple-800'>
          <i className='fa fa-credit-card'/>
        </div>
        <div className='text-white'>沔才卡</div>
      </div>

      <div className='flex flex-col items-center space-y-2' onClick={() => window.open(store.assets.from('/resources/仙桃市“沔才卡”享受服务.pdf'))}>
        <div className='w-10 h-10 rounded-full flex items-center justify-center text-lg text-white bg-gradient-to-b from-waterred-600 to-waterred-800'>
          <i className='fa fa-bullhorn'/>
        </div>
        <div className='text-white'>享受政策</div>
      </div>
    </div>

    <div className='pt-16'>
      <Steps
        current={R.call(() => {
          if(res.data?.status == -1) {
            return 0
          }

          else if(res.data?.status == 0) {
            return 1
          }

          else {
            return 2
          }
        })}
        style={{
          '--icon-size': '1.2rem',
          '--title-font-size': '0.8rem',
        }}
      >
        <Steps.Step
          icon={<i className='fa fa-circle-o'/>}
          title={res.data?.status == -1 ? '未申请“沔才卡”' : '已申请“沔才卡”'}
        />

        <Steps.Step
          title='审核中'
          icon={<i className='fa fa-circle-o'/>}
        />

        <Steps.Step
          icon={<i className='fa fa-circle-o'/>}
          title={res.data?.status < 1 ? '已通过' : res.data?.status == 1 ? '已通过' : '未通过'}
          status={res.data?.status < 1 ? 'wait' : res.data?.status == 1 ? 'finish' : 'error'}
        />
      </Steps>
    </div>
  </div>
}
