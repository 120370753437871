import Home from 'views/home'
import Apply from 'views/apply'

import Card from 'views/card'
import Certificate from 'views/certificate'

import Login from 'views/login'
import Nomatch from 'views/nomatch'

import Reapply from 'views/reapply'
import Receive from 'views/receive'

import Resume from 'plugins/resume'
import Layout from 'plugins/layout'
import Verify from 'plugins/verify'

import http from '@sar/http'
import engine from '@sar/framework'

import db from 'hooks/db'
import data from 'hooks/data'
import assets from 'hooks/assets'

import config from 'hooks/config'
import identity from 'hooks/identity'

import navigate from 'hooks/navigate'
import externals from 'hooks/externals'

import author from 'middlewares/author'
import notification from 'middlewares/notification'

const APP = (
  engine
    .create()
    .set((app: engine) => app.usePlugin(Verify))
    .set((app: engine) => app.usePlugin(Layout))
    .set((app: engine) => app.usePlugin(Resume))
    .set((app: engine) => app.usePrefix(APP_ENV.prefix))
    .set((app: engine) => app.useStore('db', db))
    .set((app: engine) => app.useStore('data', data))
    .set((app: engine) => app.useStore('assets', assets))
    .set((app: engine) => app.useStore('config', config))
    .set((app: engine) => app.useStore('identity', identity))
    .set((app: engine) => app.useStore('navigate', navigate))
    .set((app: engine) => app.useStore('externals', externals))
    .set((app: engine) => app.useRoute('/apply', Apply))
    .set((app: engine) => app.useRoute('/card', Card))
    .set((app: engine) => app.useRoute('/certificate', Certificate))
    .set((app: engine) => app.useRoute('/reapply', Reapply))
    .set((app: engine) => app.useRoute('/receive', Receive))
    .set((app: engine) => app.useRoute('/home', Home))
    .set((app: engine) => app.useRoute('/login', Login))
    .set((app: engine) => app.useRoute('/nomatch', Nomatch))
    .set((app: engine) => app.useRedirect('/', '/home'))
    .set((app: engine) => app.useRedirect('/*', '/nomatch'))
)

http.setting('base', APP_ENV.endpoint)
http.setting('adapter', APP_ENV.adapter)

http.useProxy('request', author)
http.useProxy('response', notification)

ReactDOM.render(<APP.DOM store={APP} />, document.getElementById('root'))
