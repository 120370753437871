export default {
  gradeClass: [
    {
      label: "A类",
      value: "A",
      children: [
        {
          label: "1.国际著名学术奖项获得者。",
          value: "1",
        },
        {
          label: "2.中国科学院院士、中国工程院院士、中国社会科学院学部委员（荣誉学部委员）。",
          value: "2",
        },
        {
          label: "3.世界500强企业总部首席执行官、首席运营官、首席技术官等国内外顶尖人才。",
          value: "3",
        },
      ],
    },
    {
      label: "B类",
      value: "B",
      children: [
        {
          label: "1.国家级重大人才计划入选者。",
          value: "1",
        },
        {
          label: "2.国家有突出贡献中青年专家，国家杰出青年基金项目完成人，国家科学技术奖（五大奖项）一等奖及以上获得者或项目主要完成人，“全国杰出专业技术人才”，省级杰出人才奖获奖者。",
          value: "2",
        },
        {
          label: "3.担任国家级重点实验室、学术委员会、工程实验室、能源研发中心、制造业创新中心负责人的专家学者，中国500强企业总经理级主要经营管理人才，世界500强企业总部技术研发和管理团队成员。",
          value: "3",
        },
        {
          label: "4.“国医大师”、国家级名医等荣誉表彰获得者，国家级医药奖项获得者。",
          value: "4",
        },
        {
          label: "5.国家级文化、艺术奖项获得者。",
          value: "5",
        },
        {
          label: "6.国家级教学名师。",
          value: "6",
        },
        {
          label: "7.国家级新闻奖项获得者。",
          value: "7",
        },
        {
          label: "8.国家级体育奖项获得者。",
          value: "8",
        },
      ],
    },
    {
      label: "C类",
      value: "C",
      children: [
        {
          label: "1.省级重大人才计划入选者。",
          value: "1",
        },
        {
          label: "2.省特级专家，省重点联系专家，获得“中华技能大奖”等荣誉称号的高技能人才。",
          value: "2",
        },
        {
          label: "3.省科技进步突出贡献奖获得者，国家科学技术奖二、三等奖、省级科学技术奖二等奖及以上获得者或项目主要完成人。",
          value: "3",
        },
        {
          label: "4.全国精神文明建设“五个一工程”奖单项奖获得者。",
          value: "4",
        },
        {
          label: "5.世界500强企业中的区域总部主要经营管理研发人才，国家级技术中心、科研中心及重点实验室副主任，省部级实验室、研究中心、创新中心、学术委员会主任副主任。",
          value: "5",
        },
      ]
    },
    {
      label: "D类",
      value: "D",
      children: [
        {
          label: "1.享受国务院政府特殊津贴或省政府专项津贴的专家，国际级国家级创新大赛、技能大赛获奖者，具有正高级专业技术职务任职资格的专业技术人才。",
          value: "1",
        },
        {
          label: "2.国家级重点实验室、学术委员会、能源研发中心、制造业创新中心核心成员，省部级科创中心、实验室负责人，行业头部企业、知名跨国公司高级管理者、技术部门负责人，中国500强企业中的主要经营管理研发人才。",
          value: "2",
        },
        {
          label: "3.全国优秀教育工作者，全球排名靠前院校或国家“双一流”建设、“985工程”、“211工程”等重点高校全日制博士研究生。",
          value: "3",
        },
        {
          label: "4.全国优秀医务工作者。",
          value: "4",
        },
        {
          label: "5.全国优秀体育工作者。",
          value: "5",
        },
        {
          label: "6.全国优秀文化工作者。",
          value: "6",
        },
        {
          label: "7.全国优秀新闻工作者。",
          value: "7",
        },
        {
          label: "8.省级工匠。",
          value: "8",
        },
      ]
    },
    {
      label: "E类",
      value: "E",
      children: [
        {
          label: "1.省部级实验室、研究中心、创新中心、产业技术研究院、学术委员会核心成员，连续两年缴税300万元以上专精特新企业主要经营管理者，与我市主导产业高度关联的规上企业中经营管理研发方面的主要负责人等。",
          value: "1",
        },
        {
          label: "2.省部级科学技术奖获得者或项目参与者。",
          value: "2",
        },
        {
          label: "3.具有副高级专业技术职务任职资格的专业技术人才，获得注册会计师等资格的人才。",
          value: "3",
        },
        {
          label: "4.一般高校全日制博士研究生。",
          value: "4",
        },
        {
          label: "5.市级工匠。",
          value: "5",
        },
      ]
    },
    {
      label: "F类",
      value: "F",
      children: [
        {
          label: "1.全日制硕士研究生，取得中级职称、30岁以内的国家“双一流”建设、“985工程”、“211工程”重点高校全日制本科毕业生。",
          value: "1",
        },
        {
          label: "2.我市急需紧缺的其他高校全日制本科生。",
          value: "2",
        },
        {
          label: "3.具有特殊才能的“专才”。",
          value: "3",
        },
        {
          label: "4.拥有授权发明专利2件以上且研发产品完成中试的科研人员。",
          value: "4",
        },
      ]
    }
  ],
}
