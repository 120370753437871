import { useRouteMatch } from 'react-router'
import { useStore } from '@sar/framework/hooks'

import { Title } from 'components'
import { Redirect } from 'react-router-dom'

export default (Target: React.FC<any>) => function (props: any) {
  const store = useStore()
  const route = useRouteMatch()

  const isNoLoginPage = R.cond([
    [R.equals('/login'), R.T],
    [R.equals('/nomatch'), R.T],
  ])

  if(isNoLoginPage(route.url)) {
    return <Target store={store}/>
  }

  else if(store.identity.isLogined() == false){
    return <Redirect to='/login' />
  }

  else {
    return <Target store={store} />
  }
}
