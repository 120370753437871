import { useEffect } from 'react'
import { useRequest } from 'ahooks'

import { Title } from 'components'
import { DotLoading } from 'antd-mobile'

export default function ({ store }) {
  const res = useRequest(() => store.externals.api.get('/h5/cardApply/certificate'))

  if(res.loading) {
    return <div className='h-screen flex items-center justify-center'>
      <DotLoading/>
    </div>
  }

  if(R.isEmpty(res.data)) {
    return <div className='h-screen flex items-center justify-center'>
      暂无证书
    </div>
  }

  return <div className='min-h-screen flex items-center justify-center p-4'>
    <Title>
      我的认定证书
    </Title>

    <img
      className='w-full object-cover'
      src={`data:image/jpg;base64,${res.data}`}
    />
  </div>
}
