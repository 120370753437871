import env from 'profiles/env.config'
import menus from 'profiles/menu.config'
import https from 'profiles/https.config'
import constants from 'profiles/constant.config'
import dictionary from 'profiles/dictionary.config'
import responsive from 'profiles/responsive.config'
import permissions from 'profiles/permissions.config'

export default function useConfig() {
  return {
    env,
    menus,
    https,
    constants,
    dictionary,
    responsive,
    permissions,
    getPermission: (path: string) => R.toPairs<any, any>(permissions).find(([ _, value ]) => path === value)?.[0],
    getMenuItem: (path: string) => R.flatten<any>(menus.map(R.prop('children'))).find(menu => menu.path === path),
    getConstantValues: (type: string) => R.propOr<any, any, any>([], type, constants),
    getConstantPairs: (type: string) => R.propOr<any, any, any>([], type, constants).reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {}),
    getConstantEnums: (type: string) => R.propOr<any, any, any>([], type, constants).reduce((prev, curr) => ({ ...prev, [curr.value]: curr.description }), {}),
    getConstantByValue: (type: string, value: string | number) => R.propOr<any, any, any>([], type, constants).find(data => data.value === value),
    getConstantDescriptByValue: (type: string, value: string | number) => R.propOr<any, any, any>([], type, constants).find(data => data.value === value)?.description,
  }
}
