import { Form, Cascader } from 'antd-mobile'

export default function ({ name, label, rules, options, placeholder = `请选择${label}`, onChange = R.identity as any }) {
  const [ visible, dispatch ] = React.useState(false)

  return <Form.Item
    arrow
    name={name}
    label={label}
    rules={rules}
    onClick={() => dispatch(true)}
  >
    {React.createElement(({ value, onChange }) => {
      return <Cascader
        onConfirm={onChange}
        value={value}
        visible={visible}
        options={options}
        onClose={() => dispatch(false)}
      >
        {elms => {
          if (elms.every(item => item == null)) {
            return <div className='text-gray-300'>{placeholder}</div>
          } else {
            return elms.map(item => item?.label ?? '未选择').join('-')
          }
        }}
      </Cascader>
    })}
  </Form.Item>
}
