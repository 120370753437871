import { Result } from 'antd'
import { Table } from '@sar/component'

import { useRouteMatch } from 'react-router'
import { useStore } from '@sar/framework/hooks'

export default (Target: React.FC<any>) => function (props: any) {
  const store = useStore()
  const route = useRouteMatch()

  const item = store.config.getMenuItem(route.path)
  const code = store.config.getPermission(route.path)

  if(code == null) {
    return <Target {...props}/>
  }

  if(store.identity.hasPermission(code)) {
    return <Target {...props}/>
  }

  return <div className='m-4 h-full flex flex-col justify-center bg-white'>
    <Result
      title='403'
      status='403'
      subTitle={`抱歉，您无权访问此页面(${item?.title})，请联系管理员授权后使用。`}
    />
  </div>
}
