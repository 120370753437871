import { Button } from 'antd-mobile'

export default function ({ upload, onChange }) {
  const elm = React.useRef<any>()

  return <React.Fragment>
    <input
      ref={elm}
      type='file'
      className='hidden'
      accept='image/*'
      onChange={event => upload(event.target.files?.[0]).then(onChange)}
    />

    <Button
      color='primary'
      fill='outline'
      size='small'
      onClick={() => elm.current.click()}
    >
      选择文件
    </Button>
  </React.Fragment>
}
