import { useHistory } from 'react-router'

export default function useNavigate() {
  const history = useHistory()

  return {
    back: () => history.goBack(),
    go: (url: string) => history.push(url),
  }
}
