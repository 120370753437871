import { useState } from 'react'
import { useRequest } from 'ahooks'

import { Title } from 'components'
import { Button, Form, Input, Radio, Steps, Space, Modal, DotLoading } from 'antd-mobile'

export default function ({ store }) {
  const [ form ] = Form.useForm()

  useRequest(async () => store.externals.api.get('/h5/cardApply/status').then((res => form.setFieldsValue({
    portrait: res.portrait,
    name: res.name,
    gender: res.gender,
    city: res.city,
    nation: res.nation,
    organization: res.organization,
    position: res.position,
    profession: res.profession,
    contact: res.contact,
  }))))

  const res = useRequest<any, any>(() => store.externals.api.get('/h5/card/my'), {
    cacheKey: 'card',
    staleTime: 60000,
  })

  const doSubmit = useRequest(() => store.externals.api.post('/h5/cardApply', {}), {
    manual: true,
  })

  if(res.loading) {
    return <div className='h-screen flex items-center justify-center'>
      <DotLoading/>
    </div>
  }

  if(res.data.status != -1) {
    return <Steps
      direction='vertical'
      current={R.call(() => {
        if(res.data?.status == -1) {
          return 0
        }

        else if(res.data?.status == 0) {
          return 1
        }

        else {
          return 2
        }
      })}
      style={{
        '--icon-size': '22px',
        '--title-font-size': '17px',
        '--description-font-size': '15px',
        '--indicator-margin-right': '12px',
      }}
    >
      <Steps.Step title={res.data?.status == -1 ? '未申请“沔才卡”' : '已申请“沔才卡”'} />

      <Steps.Step title='审核中'/>

      <Steps.Step
        title={res.data?.status < 1 ? '已通过' : res.data?.status == 1 ? '已通过' : '未通过'}
        status={res.data?.status < 1 ? 'wait' : res.data?.status == 1 ? 'finish' : 'error'}
      />
    </Steps>
  }

  return <div className='min-h-screen bg-gray-100 p-1'>
    <Title>
      申请沔才卡
    </Title>

    <Form disabled mode='card' layout='vertical' form={form}>
      <Form.Item
        name='portrait'
        label='电子照片'
        rules={[{ required: true }]}
      >
        {React.createElement(({ value }) => {
          if(value == null) {
            return null
          }

          return <img src={value} className='w-24 object-cover' />
        })}
      </Form.Item>

      <Form.Item
        name='name'
        label='姓名'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入姓名' />}
      />

      <Form.Item
        name='gender'
        label='性别'
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Space direction='horizontal'>
            <Radio value='male'>男</Radio>
            <Radio value='female'>女</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name='city'
        label='户籍'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入户籍' />}
      />

      <Form.Item
        name='nation'
        label='民族'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入民族' />}
      />

      <Form.Item
        name='organization'
        label='工作单位'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入工作单位' />}
      />

      <Form.Item
        name='position'
        label='职务'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入职务' />}
      />

      <Form.Item
        name='profession'
        label='从事专业'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入从事专业' />}
      />

      <Form.Item
        name='contact'
        label='联系方式'
        rules={[{ required: true }]}
        children={<Input placeholder='请输入联系方式' />}
      />
    </Form>

    <div className='p-4 pb-8 space-y-4'>
      <Button
        block
        color='primary'
        loading={doSubmit.loading}
        onClick={() => doSubmit.runAsync().then(() => Modal.confirm({ content: '申请成功，请耐心等待审核结果。'})).then(res => res ? Promise.resolve() : Promise.reject()).then(() => store.navigate.go('/card'))}
      >
        立即申请
      </Button>
    </div>
  </div>
}
