import { useState } from 'react'
import { useCountDown } from 'ahooks'

export default function ({ render, duration = 30 }) {
  const [ time, dispatch ] = useState<number>()
  const [ countdown, formatted ] = useCountDown({ targetDate: time })

  return render({
    countdown,
    formatted,
    dispatch: () => dispatch(Date.now() + duration * 1000),
  })
}
