import { useRequest } from 'ahooks'
import { useStore } from '@sar/framework/hooks'

import { Title } from 'components'
import { Redirect } from 'react-router-dom'
import { Button, DotLoading, Steps } from 'antd-mobile'

function getStatus({ finish, error, value }) {
  if(value > error) {
    return 'finish'
  }

  else if (value < finish) {
    return 'wait'
  }

  else if (value == finish) {
    return 'finish'
  }

  else {
    return 'error'
  }
}

export default function ({ store }) {
  const result = useRequest<any, any>(() => store.externals.api.get('/h5/certApply/status'), {
    cacheKey: 'status',
    cacheTime: 100000,
  })

  if(result.loading) {
    return <div className='h-screen flex items-center justify-center'>
      <DotLoading/>
    </div>
  }

  if(result.data?.status == -1) {
    return <Redirect to='/apply' />
  }

  if(result.data?.status == 3) {
    return <Redirect to='/card' />
  }

  if(result.data?.status != 99) {
    getStatus({
      finish: 1,
      error: 2,
      value: result.data.status,
    })

    getStatus({
      finish: 3,
      error: 4,
      value: result.data.status,
    })

    return <div className='p-6'>
      <Title>
        审核状态
      </Title>

      <Steps
        direction='vertical'
        style={{
          '--icon-size': '22px',
          '--title-font-size': '17px',
          '--description-font-size': '15px',
          '--indicator-margin-right': '12px',
        }}
      >
        <Steps.Step
          status='finish'
          title='提交申请'
          description='选择人才引进分类，提交资料'
        />

        <Steps.Step
          status='finish'
          title='单位公示并审核'
          description='通过单位公示并审核'
        />

        <Steps.Step
          title='审核部门实地核查'
          status={result.data.status > 2 ? 'finish' : result.data.status < 1 ? 'wait' : result.data.status == 1 ? 'finish' : 'error'}
          description={`${result.data.status > 2 ? '通过' : result.data.status < 1 ? '等待' : result.data.status == 1 ? '通过' : '未通过'}审核部门实地核查`}
        />

        <Steps.Step
          title='认定部门会议审定'
          status={result.data.status > 4 ? 'finish' : result.data.status < 3 ? 'wait' : result.data.status == 3 ? 'finish' : 'error'}
          description={`${result.data.status > 4 ? '通过' : result.data.status < 3 ? '等待' : result.data.status == 3 ? '通过' : '未通过'}认定部门会议审定`}
        />
      </Steps>

      <div className='p-4'>
        {[2, 4].includes(result.data.status) && (
          <Button
            block
            color='primary'
            onClick={() => store.navigate.go('/reapply')}
          >
            重新申请
          </Button>
        )}
      </div>
    </div>
  }

  return <div className='h-screen flex flex-col items-center justify-center'>
    <h1 className='text-xl font-bold'>人才引进分类认证</h1>

    <ul className='text-center mt-4 leading-normal'>
      <li>为优化便民服务</li>
      <li>现通过统一线上入口进行申请</li>
      <li>先认定人才引进分类，后选择申领”沔才卡“。</li>
      <li>通过各部门初审</li>
      <li>市委人才工作领导小组终审。</li>
      <li>终审通过后公示5个工作日，认定生效。</li>
    </ul>

    <div className='flex justify-center mt-14'>
      <img src={store.assets.from('/images/card.png')} className='h-36' />
    </div>

    <div className='w-full max-w-xs px-4 mt-8'>
      <Button
        block
        color='primary'
        onClick={() => store.navigate.go('/apply')}
      >
        开始认证
      </Button>
    </div>
  </div>
}
