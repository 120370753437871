import { Toast } from 'antd-mobile'

export default function notification(response) {
  if([ 200, 201, 204 ].includes(response.code)) {
    return Promise.resolve(response.data)
  }

  else if(response.code == 400) {
    return Promise.reject(response.data.message).finally(() => Toast.show(response.data.message))
  }

  else if(response.code == 401) {
    return Promise.reject('登录凭证已过期').finally(() => Toast.show('登录凭证已过期')).finally(() => localStorage.removeItem('db:uid')).finally(() => location.replace(APP_ENV.prefix ?? '' + '/login'))
  }

  else {
    return Promise.reject('系统错误').finally(() => Toast.show('系统错误'))
  }
}
