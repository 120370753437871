import SignatureCanvas from 'react-signature-canvas'

export default function ({ value, onChange }) {
  const [ area, dispatch ] = React.useState({ width: 0, height: 0 })

  const elm = React.useRef<any>()
  const canvas = React.useRef<any>()

  React.useEffect(() => {
    if(elm.current) {
      dispatch({
        width: elm.current?.offsetWidth * window.devicePixelRatio,
        height: elm.current?.offsetHeight * window.devicePixelRatio,
      })
    }
  }, [])

  React.useEffect(() => {
    if(area.width > 0) {
      canvas.current.getCanvas().getContext('2d').scale(
        window.devicePixelRatio,
        window.devicePixelRatio,
      )
    }
  }, [ area ])

  React.useEffect(() => {
    if(area.width > 0) {
      canvas.current.fromDataURL(value)
    }
  }, [ area ])

  return <div ref={elm} className='w-full'>
    <SignatureCanvas
      onEnd={() => onChange(canvas.current?.toDataURL())}
      ref={canvas}
      penColor='black'
      canvasProps={{ width: area.width, height: area.height, willReadFrequently: true, style: { width: '100%', height: 200 } }}
    />

    <div className='flex justify-end'>
      <a className='text-sm' onClick={R.compose(R.tap(() => onChange()), R.tap(() => canvas.current?.clear()))}>清除白板</a>
    </div>
  </div>
}
