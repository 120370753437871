import { useState } from 'react'
import { useRequest } from 'ahooks'

import { Captcha } from 'components'
import { Form, Button, Toast } from 'antd-mobile'

export default function ({ store }) {
  const [ form ] = Form.useForm()

  const doLogin = useRequest(async (data) => {
    const result = await store.externals.api.post('/h5/login', {
      phone: data.username,
      code: data.verifycode,
    })

    store.identity.dispatch('user', result.user)
    store.identity.dispatch('token', result.token)
    store.identity.dispatch('uid', result.user.id)
  }, { manual: true })

  return <div className='h-screen flex flex-col items-center justify-center space-y-4'>
    <h1 className='text-xl font-bold'>人才引进分类认证</h1>

    <div className='flex justify-center'>
      <img src={store.assets.from('/images/card.png')} className='h-28' />
    </div>

    <div className='w-full max-w-xs'>
      <Form form={form} requiredMarkStyle='none' style={{ '--border-bottom': 'none', '--border-inner': 'none', '--border-top': 'none'  }}>
        <Form.Item name='username' label='手机号' rules={[{ required: true }, { validator: (_, value) => /^1\d{10}$/.test(value) ? Promise.resolve() : Promise.reject('请输入正确的手机号') }]} noStyle>
          {React.createElement(({ value, onChange }) => {
            return <div className='flex items-center m-4 pl-0 border border-gray-300 rounded-sm'>
              <div className='flex justify-center items-center flex-none w-8 text-lg'>
                <i className='fa fa-user-circle-o text-gray-400'/>
              </div>

              <input
                value={value}
                onChange={e => onChange(e.target.value)}
                placeholder='填写手机号'
                className='flex-auto p-0 my-2 bg-white outline-none text-sm placeholder-gray-300'
              />
            </div>
          })}
        </Form.Item>

        <Form.Item name='verifycode' label='验证码' rules={[{ required: true }, { len: 6 }]} noStyle>
          {React.createElement(({ value, onChange }) => {
            return <div className='flex items-center m-4 pl-0 border border-gray-300 rounded-sm'>
              <div className='flex justify-center items-center flex-none w-8 text-lg'>
                <i className='fa fa-key text-gray-400'/>
              </div>

              <input
                value={value}
                onChange={e => onChange(e.target.value)}
                placeholder='填写验证码'
                className='flex-auto p-0 my-2 bg-white outline-none text-sm placeholder-gray-300'
              />

              <Captcha render={({ dispatch, countdown }) => {
                if(countdown > 0) {
                  return <a className='mr-2 flex-none text-xs'>
                    {Math.round(countdown / 1000)}秒后获取
                  </a>
                }

                return <a className='mx-2 flex-none text-xs' onClick={() => form.validateFields().catch(R.identity).then(() => {
                  if(R.isEmpty(form.getFieldError('username'))) {
                    store.externals.api.get(`/h5/phone/code/${form.getFieldValue('username')}`).then(() => dispatch())
                  }

                  else {
                    Toast.show(R.head(form.getFieldError('username')))
                  }
                })}>
                  获取验证码
                </a>
              }}/>
            </div>
          })}
        </Form.Item>
      </Form>
    </div>

    <div className='w-full max-w-xs px-4'>
      <Button
        block
        color='primary'
        loading={doLogin.loading}
        onClick={() => form.validateFields().catch(R.identity).then(() => form.getFieldsError().map(R.prop('errors')).reduce((prev, current) => prev.concat(current), [])).then(errors => {
          if(R.isEmpty(errors)) {
            doLogin.runAsync(form.getFieldsValue()).then(() => store.navigate.go('/home'))
          }

          else {
            Toast.show(R.head(errors))
          }
        })}
      >
        登录
      </Button>
    </div>
  </div>
}
