import api from '@sar/http'
import useOss from '@sar/framework/hooks/useOss'

export default function useExternals() {
  const oss = useOss(APP_ENV.oss)

  return {
    api,
    oss,
  }
}
